<template>
	<div class="w-full flex-grow py-12 px-8 flex flex-col">
		<div class="flex flex-col justify-start items-start space-y-5">
			<!-- Owner Name -->
			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Owner Name (as it appears in JobNimbus)</h3>
				<input v-model="formData.jnName" type="text" class="px-3 py-2 border w-full" :class="{ 'border-red-500': errors.jnName }" />
				<span v-if="errors.jnName" class="text-red-500 text-sm"> Owner Name is required. </span>
			</div>

			<!-- What happened at this lead? -->
			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">What happened at this lead?</h3>
				<select v-model="formData.whatHappened" class="px-3 py-2 border w-full" :class="{ 'border-red-500': errors.whatHappened }">
					<option value="">Select an option</option>
					<option value="ran">Ran</option>
					<option value="ran_no_one_legger">Ran, no/one legger</option>
					<option value="follow_up">Follow Up - not new lead</option>
					<option value="cancelled">Cancelled</option>
					<option value="rescheduled">Rescheduled</option>
					<option value="no_show">No Show</option>
				</select>
				<span v-if="errors.whatHappened" class="text-red-500 text-sm"> Please select what happened at this lead. </span>
			</div>

			<!-- Did you demo? -->
			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Did you demo?</h3>
				<select v-model="formData.didYouDemo" class="px-3 py-2 border w-full" :class="{ 'border-red-500': errors.didYouDemo }">
					<option value="">Select an option</option>
					<option value="yes">Yes</option>
					<option value="no">No</option>
					<option value="out_of_scope">Out of Scope</option>
					<option value="scheduled_for_later">Scheduled for later date</option>
				</select>
				<span v-if="errors.didYouDemo" class="text-red-500 text-sm"> Please select if you demoed. </span>
			</div>

			<!-- Did you sign? -->
			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Did you sign...</h3>
				<p class="text-xs opacity-60">ONLY COUNT AS CONTRACTED WITH DEPOSIT OR APPROVED FINANCING</p>
				<select v-model="formData.didYouSign" class="px-3 py-2 border w-full" :class="{ 'border-red-500': errors.didYouSign }">
					<option value="">Select an option</option>
					<option value="contract">Contract</option>
					<option value="contingency">Contingency</option>
					<option value="no">No</option>
				</select>
				<span v-if="errors.didYouSign" class="text-red-500 text-sm"> Please select if you signed. </span>
			</div>

			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Contract Amount</h3>
				<p class="text-xs opacity-60">$ for contract signed (if nothing signed, enter 0) REQUIRED</p>
				<input v-model="formData.contractAmount" type="number" class="px-3 py-2 border w-full" :class="{ 'border-red-500': errors.contractAmount }" />
				<span v-if="errors.contractAmount" class="text-red-500 text-sm"> Contract amount is required. </span>
			</div>

			<!-- Did you apply for financing? -->
			<div class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Did you apply for financing?</h3>
				<select v-model="formData.appliedForFinancing" class="px-3 py-2 border w-full" :class="{ 'border-red-500': errors.appliedForFinancing }">
					<option value="">Select an option</option>
					<option value="not_needed">Not Needed</option>
					<option value="yes">Yes</option>
				</select>
				<span v-if="errors.appliedForFinancing" class="text-red-500 text-sm"> Please select if financing was applied for. </span>
			</div>

			<!-- Was the homeowner approved? -->
			<div class="flex flex-col space-y-1 w-full" v-if="formData.appliedForFinancing === 'yes'">
				<h3 class="text-sm opacity-70">ONLY ANSWER IF YOU APPLIED FOR FINANCING!!!!!</h3>
				<h4 class="text-sm opacity-70">Was the homeowner approved?</h4>
				<select v-model="formData.homeownerApproved" class="px-3 py-2 border w-full" :class="{ 'border-red-500': errors.homeownerApproved }">
					<option value="">Select an option</option>
					<option value="yes">Yes</option>
					<option value="no">No</option>
				</select>
				<span v-if="errors.homeownerApproved" class="text-red-500 text-sm"> Please specify if the homeowner was approved. </span>
			</div>

			<!-- Contract amount if not approved -->
			<div class="flex flex-col space-y-1 w-full" v-if="formData.homeownerApproved === 'no'">
				<h3 class="text-sm opacity-70">IF NOT APPROVED for financing, what was the contract amount?</h3>
				<input
					v-model="formData.contractAmountIfNotApproved"
					type="number"
					class="px-3 py-2 border w-full"
					:class="{ 'border-red-500': errors.contractAmountIfNotApproved }"
				/>
				<span v-if="errors.contractAmountIfNotApproved" class="text-red-500 text-sm"> Please provide the contract amount if not approved. </span>
			</div>

			<!-- Why did they not sign today? -->
			<div v-if="formData.didYouSign == 'no'" class="flex flex-col space-y-1 w-full">
				<h3 class="text-sm opacity-70">Why did they not sign today?</h3>
				<div class="flex flex-col space-y-2">
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="price_too_high" />
						Price too high
					</label>
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="collecting_estimates" />
						Collecting Estimates/Shopping Around
					</label>
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="thinking_about_it" />
						Thinking About It
					</label>
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="not_all_decision_makers_present" />
						Not all decision makers present
					</label>
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="goodleap_denial" />
						GoodLeap Denial
					</label>
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="insurance" />
						HO wanted to go through Insurance
					</label>
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="none" />
						None of the above
					</label>
					<label>
						<input type="checkbox" v-model="formData.notSignedReasons" value="free_roof_only" />
						Free Roof Only
					</label>
				</div>
				<span v-if="errors.notSignedReasons" class="text-red-500 text-sm"> Please select at least one reason why they did not sign. </span>
			</div>
		</div>

		<button
			@click="next"
			:class="`bg-${store.theme.color}-500 border-${store.theme.color}-600`"
			class="text-xl px-4 py-2 rounded-xl text-white uppercase border-b-4 self-end mt-8"
		>
			Submit
		</button>
	</div>
</template>

<script>
import Swal from 'sweetalert2'
import Mango from '../../helpers/mango.js'
import Address from '../layout/address.vue'

export default {
	components: { Address },
	props: ['opportunity'],
	inject: ['store'],
	data() {
		return {
			savingOpportunity: false,
			address: {
				formatted: null,
			},
			errors: {},
			formData: {
				jnName: this.opportunity?.jnName || '',
				whatHappened: this.opportunity?.whatHappened || '',
				didYouDemo: this.opportunity?.didYouDemo || '',
				didYouSign: this.opportunity?.didYouSign || '',
				revenue: this.opportunity?.revenue || null,
				appliedForFinancing: this.opportunity?.appliedForFinancing || '',
				homeownerApproved: this.opportunity?.homeownerApproved || '',
				contractAmountIfNotApproved: this.opportunity?.contractAmountIfNotApproved || null,
				notSignedReasons: this.opportunity?.notSignedReasons || [],
			},
			revenue: null,
		}
	},
	watch: {
		savingOpportunity() {
			this.$emit('saving', this.savingOpportunity)
		},
	},
	methods: {
		validateForm() {

            this.errors = {}; // Reset errors

            const requiredFields = [
                { field: 'jnName', condition: !this.formData.jnName },
                { field: 'whatHappened', condition: !this.formData.whatHappened },
                { field: 'didYouDemo', condition: !this.formData.didYouDemo },
                { field: 'didYouSign', condition: !this.formData.didYouSign },
                { field: 'contractAmount', condition: !this.formData.contractAmount },
                { field: 'appliedForFinancing', condition: !this.formData.appliedForFinancing },
                { field: 'homeownerApproved', condition: this.formData.appliedForFinancing === 'yes' && !this.formData.homeownerApproved },
                { field: 'contractAmountIfNotApproved', condition: this.formData.homeownerApproved === 'no' && !this.formData.contractAmountIfNotApproved },
                { field: 'notSignedReasons', condition: this.formData?.didYouSign === 'no' && this.formData.notSignedReasons.length === 0 }
            ];

            requiredFields.forEach(({ field, condition }) => {
                if (condition) {
                    this.errors[field] = true;
                }
            });

		},
		async next() {
			this.validateForm()
			let invalidFields = Object.keys(this.errors)
			if (invalidFields)
				return Swal.fire({
					icon: 'warning',
					text: `Please enter a value for ${invalidFields?.map(f => f.toTitleCase('camel')).join(', ')}.`,
				})
			this.savingOpportunity = true
			let timestamps = this.opportunity.timestamps || {}
			timestamps.revenue = this.revenue ? new Date() : null
			await this.store.localDB.save({
				id: this.opportunity.id,
				ownerInfo: this.formData,
				revenue: this.revenue,
				timestamps,
			})
			this.$router.push(`/opportunities/${this.opportunity.id}/lead-result`)
		},
	},
	created() {
		const savedInfo = this.opportunity?.ownerInfo || {}
		this.revenue = this.opportunity?.revenue || null
		this.formData = { ...this.formData, ...savedInfo }
	},
}
</script>
