let calculateXp = ktwc => {
    let xp = 0
    let contingencies = ktwc.contingency % 3
    let hatTricks = (ktwc.contingency - contingencies) / 3
    xp += ktwc.knock * 1
    xp += hatTricks * 200
    xp += contingencies * 50
    xp += (ktwc?.contractSigned || ktwc?.contracts || 0) * 300
    return xp
}

export default calculateXp
