<template>

    <div class="w-full flex-grow py-12 px-8 flex flex-col justify-start items-start">

        <div>
            <h3 class="text-lg mb-4">Already having their roof replaced?</h3>
            <div class="flex gap-4 text-sm mb-8">
                <!-- <btn @click="bcr" fontSize="text-lg !normal-case" label="With BCR" :selected="formData.objections?.includes('with bcr')" />
                <btn @click="bcr" fontSize="text-lg !normal-case" label="With Someone Else" :selected="formData.objections?.includes('with other')" /> -->
                <button @click="serviced('bcr')" :class="`bg-${store.theme.color}-500 border-${store.theme.color}-600`" class="text-lg px-4 py-2 rounded-xl text-white border-b-4 self-end">
                    With BCR
                </button>
                <button @click="serviced('other')" :class="`bg-${store.theme.color}-500 border-${store.theme.color}-600`" class="text-lg px-4 py-2 rounded-xl text-white border-b-4 self-end">
                    With Someone Else
                </button>
            </div>
        </div>

        <div class="flex flex-col space-y-6 pb-12">
			<h3 class="text-lg">Check all objections that apply</h3>
            <div class="space-y-4 pl-4">
                <label v-for="option in possibleObjections" :key="option" class="flex space-x-4 items-center text-sm">
                    <input v-model="formData.objections" type="checkbox" :value="option" />
                    <div>{{ option }}</div>
                </label>
            </div>
        </div>

		<div class="flex flex-col space-y-2 w-full mb-8">
			<h3 class="text-lg">Notes</h3>
			<textarea v-model="formData.notes" class="px-3 py-2 border w-full resize-none" rows="5"></textarea>
		</div>

		<div class="flex flex-col space-y-2 w-full">
			<h3 class="text-lg">Warnings</h3>
            <p class="text-sm opacity-50">Add a warning for this property. (ie: Beware of dog!)</p>
			<textarea v-model="addressHazards" class="px-3 py-2 border w-full resize-none" rows="5"></textarea>
		</div>

        <button @click="next" :class="`bg-${store.theme.color}-500 border-${store.theme.color}-600`" class="text-xl px-4 py-2 rounded-xl text-white uppercase border-b-4 self-end mt-16">
            Submit
        </button>

    </div>

</template>

<script>
import Mango from '../../helpers/mango.js'

export default {
    props: ['opportunity'],
    inject: ['store'],
    data() {
        return {
            savingOpportunity: false,
            formData: {
                objections: [],
                notes: null,
            },
            addressHazards: null,
            possibleObjections: [
                'Renter.',
                'Too busy.',
                'Too expensive.',
                'My cousin is a roofer.',
                'I don\'t want my premiums to go up.',
                'There is nothing wrong with my roof.',
            ],
        }
    },
    watch: {
        savingOpportunity() {
            this.$emit('saving', this.savingOpportunity)
        }
    },
    methods: {
        serviced(provider) {
            this.formData.objections = [`with ${provider}`]
            this.next()
        },
        async next() {
            this.savingOpportunity = true
            this.formData.objections = Array.from(new Set(this.formData.objections))
            this.opportunity.id = (await this.store.localDB.save({ id: this.opportunity.id, objections: this.formData, addressHazards: this.addressHazards }))?.id
            this.opportunity.objections = this.formData
            this.savingOpportunity = false
            if (this.formData.objections.includes('with bcr')) return this.$router.push('/')
            this.$router.push(`/opportunities/${this.opportunity.id}/opportunity-info`)
        }
    },
	mounted() {
		const savedObjections = this.opportunity.objections
		if (savedObjections?.objections.length) this.formData.objections = savedObjections.objections
		if (savedObjections?.notes) this.formData.notes = savedObjections.notes
		if (this.opportunity?.addressHazards) this.addressHazards = this.opportunity.addressHazards
	}
}
</script>
