// import { useRegisterSW } from "virtual:pwa-register/vue";

// const intervalMS = 60 * 60 * 1000

// const updateServiceWorker = useRegisterSW({
//     onRegistered(r) {
//         r && setInterval(() => {
//             r.update()
//         }, intervalMS)
//     }
// })

String.prototype.toTitleCase = function (originCase) {
    let str = `${this.toString()}`;

    switch (originCase) {
        case 'camel':
            str = str.replace(/([A-Z])/g, ' $1');
            break;
        case 'snake':
            str = str.replace(/_/g, ' ');
            break;
        case 'kebab':
            str = str.replace(/-/g, ' ');
            break;
        case 'pascal':
            str = str.replace(/([A-Z])/g, ' $1').trim();
            break;
    }

    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

// import settings from '../../mango/config/config/settings.json'

import axios from "axios";

import { computed, createApp, defineAsyncComponent, reactive, ref } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { MotionPlugin } from "@vueuse/motion";
import { apiDomain, port } from "../../config/config/settings.json";
import VueClipboard from "vue3-clipboard";
import Vue3TouchEvents from "vue3-touch-events";
import App from "./App.vue";
import "./index.css";

/*
    If these are dynamicly imported I end up with a blank
    screen while the route is waiting for the component
    to download... not sure how to fix.
*/

import Login from "./components/layout/login.vue";
import Signup from "./components/pages/signup.vue";
import Home from "./components/pages/home.vue";
import Opportunity from "./components/pages/opportunity.vue";
import Observations from "./components/pages/observations.vue";
import Retail from "./components/pages/retail.vue";
import LeadResult from "./components/pages/lead-result.vue";
import DamageLocation from "./components/pages/damage-location.vue";
import Selfie from "./components/pages/selfie.vue";
import Discuss from "./components/pages/discuss.vue";
import Inspect from "./components/pages/inspect.vue";
import OwnerInfo from "./components/pages/owner-info.vue";
import Ink from "./components/pages/ink.vue";
import Objections from "./components/pages/objections.vue";
import OpportunityInfo from "./components/pages/opportunity-info.vue";
import Shotgun from "./components/pages/shotgun.vue";
import FAQ from "./components/pages/faq.vue";
import Estimate from "./components/pages/estimate.vue";
import Chat from "./components/pages/chat.vue";
import MaterialOrder from "./components/pages/documents/material-order.vue";

import MyOpportunities from "./components/pages/my-opportunities.vue";
import Account from "./components/pages/account.vue";

// const isDev = ['true', '1'].includes(import.meta.env.VITE_DEVMODE)
// const protectRoutes = isDev ? false : true;

const routes = [

    { name: 'home', path: '/', component: Home, meta: { protected: true, fallback: '/login' } },

    { name: 'chat', path: '/chat', component: Chat, meta: { protected: false, fallback: '/login' } },
    { name: 'faq', path: '/faq', component: FAQ, meta: { protected: false, fallback: '/login' } },
    { name: 'estimate', path: '/estimate', component: Estimate, meta: { protected: true, fallback: '/login' } },

    { path: '/login', component: Login },
    { path: '/login/:company', component: Login },

    { path: '/shotgun', component: Shotgun, meta: { protected: true, fallback: '/login' } },

    { path: '/account', component: Account, meta: { protected: true, fallback: '/login' } },
    { path: '/account/signup', component: Signup, meta: { protected: true, fallback: '/login' } },
    { path: '/account/my-opportunities', component: MyOpportunities, meta: { protected: true, fallback: '/login' } },

    { name: "op-estimate", path: "/opportunities/:id/estimate", component: Estimate, meta: { protected: true, fallback: "/login" } },

    {
        path: "/opportunities/:id/",
        component: Opportunity,
        children: [
            { name: "observations", path: "observations", component: Observations },
            { name: "retail", path: "retail", component: Retail },
            { name: "lead-result", path: "lead-result", component: LeadResult },
            { name: "damage-location", path: "damage-location", component: DamageLocation },
            { name: "selfie", path: "selfie", component: Selfie },
            { name: "discuss", path: "discuss", component: Discuss },
            { name: "inspect", path: "inspect", component: Inspect },
            { name: "owner-info", path: "owner-info", component: OwnerInfo },
            { name: "insurance-info", path: "ink", component: Ink },
            { name: "objections", path: "objections", component: Objections },
            {
                name: "opportunity-info",
                path: "opportunity-info",
                component: OpportunityInfo,
            },
        ],
        meta: { protected: true, fallback: "/login" },
    },
];

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        let toPath = to.matched?.[0]?.path;

        let sameComponent =
            to.matched?.[0]?.components?.default ==
            from.matched?.[0]?.components?.default;
        let sameComponentExceptions = ["/inventory/:id", /account\/*/g];
        let exceptionExists = sameComponentExceptions.some(
            (e) => !!toPath?.match(e)?.length
        );

        if (to.hash) {
            return { el: to.hash, behavior: "smooth" };
        }
        if (savedPosition) return savedPosition;
        if (sameComponent && !exceptionExists) return null;

        return { top: 0 };
    },
    routes,
});

const app = createApp(App);

app.use(router);
app.use(Vue3TouchEvents);
app.use(MotionPlugin);
app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
});

import VueLazyload from "vue-lazyload";
app.use(VueLazyload);

/*

Global Properties

*/

import breakpoints from "./helpers/breakpoints";
import darkMode from "./helpers/darkMode";
import formatPhone from "./helpers/formatPhone";
import LocalDB from "./helpers/localDB";
// import useCart from './helpers/cart'
// import useRegistration from './helpers/registration';
import { initUser } from "./helpers/user";

app.provide("breakpoints", breakpoints().breakpoints);
app.provide("darkMode", darkMode().darkMode);

const user = initUser();

let companyDefault = {
    color: "red",
    contact: "Ring",
    logo: {
        url: "https://bcr-rep-portal.s3.us-east-1.amazonaws.com/assets/companies/63a1d5f115e332e736668e93/logo.png?updated=1699898789212",
    },
};

console.log("user", user.value);

let api = apiDomain;
if (process.env.NODE_ENV != "production") {
    api = `http://localhost:${port}`;
}

const store = reactive({
    user,
    localDB: new LocalDB('opportunities', api),
    theme: window.location.href.includes('localhost') ? companyDefault : window.company || companyDefault,
    nativeApp: navigator.userAgent.includes('median') ? true : false,
    production: process.env.NODE_ENV == 'production',
    api,
    ktwc: {
        knock: 0,
        talk: 0,
        walk: 0,
        contingency: 0,
        contractSigned: 0
    },
    localKtwc: {
        knock: 0,
        talk: 0,
        walk: 0,
        contingency: 0,
        contractSigned: 0
    },

    touchDevice:
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0,
    online: navigator.onLine,
});

setInterval(async () => {
    store.online = navigator.onLine;
}, 1000);

let Authorization = window.localStorage.getItem("auth");
axios.defaults.headers.common["Authorization"] = Authorization;

app.provide("store", store);
app.provide("axios", axios);

app.provide("mode", import.meta.env.MODE);
app.provide("formatPhone", formatPhone);

/*

Global Components

*/

import Mango from "./helpers/Mango.vue";
import Spinner from "./components/layout/spinner.vue";
import Modal from "./components/layout/modal.vue";
import Button from "./components/partials/button.vue";

app.component("Mango", Mango);
app.component("Spinner", Spinner);
app.component("Modal", Modal);
app.component("btn", Button);
// app.component('resource', defineAsyncComponent(Layouter.vue')))

/*

Route Guard

*/

router.beforeEach((to, from, next) => {
    // Log in google analytics...
    if (window.ga) window.ga("send", "pageview", to.fullPath);

    let protectedRoute = to.meta?.protected;
    let roles = store.user?.roles;
    let loggedIn = !!store.user?.id;
    let admin = roles?.includes("admin");
    let local = window.location.href.includes("localhost");

    if (!protectedRoute || admin || local) return next();

    let approved = Array.isArray(protectedRoute)
        ? protectedRoute.some((role) => roles?.includes(role))
        : loggedIn;

    if (approved) return next();
    else {
        console.log("Protected:", protectedRoute, roles);
        store.login = { next: to.path };

        if (!to.meta?.fallback) return next("");

        // Get params from path and add them to the fallback path (/event/:id)
        let fallback = to.meta.fallback
            ?.split("/")
            ?.map((p) => {
                if (p.includes(":")) {
                    let param = p.replaceAll(":", "");
                    return to.params[param] || "";
                } else {
                    return p;
                }
            })
            ?.join("/");

        return next(fallback || to.meta.fallback);
    }
});

/*

SENTRY

*/

// import * as Sentry from "@sentry/vue";

// Sentry.init({
//     app,
//     dsn: "https://fe5b510cdbb552d571010e901c6969d5@o4506316278595584.ingest.sentry.io/4506316280889344",
//     integrations: [
//         new Sentry.BrowserTracing({
//             // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//             tracePropagationTargets: ["localhost", /^https:\/\/app\.bestchoiceroofing\.com/],
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         }),
//         new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

app.mount("#app");
